
.user-info-dropdown-menu{
    &:hover{
        .dropdown-menu{
            display: block;
        }
    }
}
.ucenter{
    background-color: #F3F9FF;
    .ucenter-body{
        //background-color: #FFF;
    }
    .ucenter-main{
        //padding: 1em 1em 1em 0;
    }
    .ucenter-aside, .ucenter-main-block{
        background-color: #FFF;
        border-radius: 1rem;
        margin-bottom: 1em;
        border: none;
    }
    .ucenter-aside {
        box-shadow: -0.125rem 0.125rem 0.25rem rgb(0 0 0 / 8%);
    }
    .ucenter-main-block {
        box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
    }
    .ucenter-main-block-title{
        border-left: solid 3px #EC5028;
        padding-left: .5em;
        font-weight: bold;
    }
    .search-form{
        background-color: #F7F7F7;
        margin: 0 -1rem;
        padding: .5rem 0;
    }
}

.ucenter-menu-wrap{
    width: 200px;
    height: 100%;
    .nav{
        .nav-link{
            color: #2771BC;
            padding: 0.8em 1em;
            border-left: 3px solid transparent;
            &:hover{
                background: #F3F9FF;
                text-decoration: none;
            }
            &.active{
                background-color: #F3F9FF;
                border-left-color: #2771BC;
            }
            svg.pull-right{
                margin-top: 4px;
                float: right;
            }
        }
    }
}
.member-info{
    .u-items{
        overflow: hidden;
        .u-item{
            float: left;
        }
    }
}
