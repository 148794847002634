.feature-box {
  border-radius: 0.6em;
  background-image: linear-gradient(to right, #45D4DB, #4DB6F5);
  min-width: 256px;
}

.feature-text {
  color: #fff;
}

.feature-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  margin-bottom: 1rem;
  font-size: 2rem;
  background-color: #FFF;
  border-radius: 50%;
}

body {
  color: #1d1d1f;
  line-height: 1.6;
  font-family: -apple-system-font, Helvetica Neue, sans-serif;
}

.aside {
  flex: 0 0 auto;
  width: 280px;
}

.iconfont {
  font-size: 1em;
  line-height: 1;
}

.navbar-nav .badge {
  top: -1.5em;
  right: 5px;
  text-align: center;
  font-size: 9px;
  padding: 2px 3px;
  line-height: 0.9;
}

.date-range .input-group-text {
  background-color: #FFF;
  border-left: none;
  border-right: none;
}
.date-range .form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
}
.date-range .form-control:first-child {
  border-right: none;
}
.date-range .form-control:last-child {
  border-left: none;
}

.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

a.label:hover,
a.label:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.label:empty {
  display: none;
}

.btn .label {
  position: relative;
  top: -1px;
}

.label-default {
  background-color: #777777;
}

.label-default[href]:hover,
.label-default[href]:focus {
  background-color: #5e5e5e;
}

.label-primary {
  background-color: #337ab7;
}

.label-primary[href]:hover,
.label-primary[href]:focus {
  background-color: #286090;
}

.label-success {
  background-color: #5cb85c;
}

.label-success[href]:hover,
.label-success[href]:focus {
  background-color: #449d44;
}

.label-info {
  background-color: #5bc0de;
}

.label-info[href]:hover,
.label-info[href]:focus {
  background-color: #31b0d5;
}

.label-warning {
  background-color: #f0ad4e;
}

.label-warning[href]:hover,
.label-warning[href]:focus {
  background-color: #ec971f;
}

.label-danger {
  background-color: #d9534f;
}

.label-danger[href]:hover,
.label-danger[href]:focus {
  background-color: #c9302c;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

a img {
  border: 0;
}

a {
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #1ca2f9;
}

.notice-box {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 65535;
  background-color: rgba(0, 0, 0, 0.4);
}
.notice-box .notice-body {
  position: relative;
  background-color: #FFF;
  pointer-events: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  min-width: 300px;
}
.notice-box .notice-icon {
  font-size: 48px;
}
#alert-modal {
  z-index: 65534;
}

#confirm-modal {
  z-index: 65533;
}

.btn-file {
  position: relative;
}

.btn input[type=file] {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
}

.img-upload-viewer {
  width: 218px;
  height: 137.5px;
  background-color: #FFF;
  border-radius: 8px;
  overflow: hidden;
}
.img-upload-viewer img.viewer {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
.img-upload-viewer .viewer-wrap {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.footer-info {
  background-color: #0071BC;
  color: white;
  font-size: 0.8em;
}

#user-info-load .btn-passport {
  border-radius: 38px;
  color: white;
}
#user-info-load .btn-login {
  background-image: linear-gradient(to right, #edbd6a, #f6a76a);
}
#user-info-load .btn-signup {
  background-image: linear-gradient(to right, #106dde, #1897f3);
}

.btn-info {
  color: #FFF;
}

.navbar-nav .nav-link {
  color: #0071bc;
  border-bottom: solid 2px transparent;
  font-weight: bold;
}
.navbar-nav .nav-link:hover, .navbar-nav .nav-link.active {
  border-bottom-color: #edbd6a;
}

.licences .licence {
  width: 132px;
  height: 50px;
  background-color: #FFF;
}
.licences .licence a {
  padding: 5px;
}
.licences .licence .licence-icon {
  flex-shrink: 0;
  margin: 4px;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-image: url("../img/iconimg.png");
}
.licences .licence .licence-icon.icon-gsxt {
  background-image: url("../img/gsxt.gov.cn.png");
  background-size: contain;
}
.licences .licence .licence-icon.icon-dxzhgl {
  background-image: url("../img/beian.miit.gov.cn.png");
  background-size: contain;
}
.licences .licence .licence-icon.icon-12377 {
  background-image: url("../img/12377.cn.png");
  background-size: contain;
}
.licences .licence .licence-icon.icon-beian {
  background-position: -45px -182px;
}
.licences .licence .licence-icon.icon-cyberpolice {
  background-position: -80px -180px;
}
.licences .licence .licence-text {
  font-size: smaller;
  width: 5em;
  margin-left: 5px;
}

.block-title {
  color: #fbae17;
  position: relative;
}
.block-title:after {
  content: " ";
  width: 2em;
  height: 1px;
  border: 2px solid #1ca2f9;
  position: absolute;
  bottom: -8px;
  left: 50%;
  margin: 0 auto 0 -1em;
}

.block-subtitle {
  color: #29abe2;
  margin-bottom: 150px;
}

.passport .passport-wrap {
  max-width: 400px;
  width: 100%;
}

.user-info-dropdown-menu:hover .dropdown-menu {
  display: block;
}

.ucenter {
  background-color: #F3F9FF;
}
.ucenter .ucenter-aside, .ucenter .ucenter-main-block {
  background-color: #FFF;
  border-radius: 1rem;
  margin-bottom: 1em;
  border: none;
}
.ucenter .ucenter-aside {
  box-shadow: -0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.08);
}
.ucenter .ucenter-main-block {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.08);
}
.ucenter .ucenter-main-block-title {
  border-left: solid 3px #EC5028;
  padding-left: 0.5em;
  font-weight: bold;
}
.ucenter .search-form {
  background-color: #F7F7F7;
  margin: 0 -1rem;
  padding: 0.5rem 0;
}

.ucenter-menu-wrap {
  width: 200px;
  height: 100%;
}
.ucenter-menu-wrap .nav .nav-link {
  color: #2771BC;
  padding: 0.8em 1em;
  border-left: 3px solid transparent;
}
.ucenter-menu-wrap .nav .nav-link:hover {
  background: #F3F9FF;
  text-decoration: none;
}
.ucenter-menu-wrap .nav .nav-link.active {
  background-color: #F3F9FF;
  border-left-color: #2771BC;
}
.ucenter-menu-wrap .nav .nav-link svg.pull-right {
  margin-top: 4px;
  float: right;
}

.member-info .u-items {
  overflow: hidden;
}
.member-info .u-items .u-item {
  float: left;
}

.shortcut {
  background: #f5f5f5;
  border-bottom: 1px solid #ddd;
  color: #999;
  height: 1.875rem;
  line-height: 1.875rem;
}
.shortcut .fl {
  float: left;
}
.shortcut .fr {
  float: right;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.shortcut a {
  color: #999;
  text-decoration: none;
}

.shortcut a:hover {
  color: #e33333;
}

.shortcut .site-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.shortcut .site-menu li {
  float: left;
}

.shortcut li.spacer {
  overflow: hidden;
  margin: 11px 5px 0;
  width: 1px;
  height: 10px;
  background-color: #ccc;
}

.shortcut .site-menu-item {
  position: relative;
}

.shortcut .site-menu-item .dt {
  padding: 0 6px;
  z-index: 1;
}

.shortcut .site-menu-item.dropdown:hover {
  background-color: #FFF;
}

.shortcut .site-menu-item.dropdown:hover .dropdown-layer {
  display: block;
}

.shortcut .site-menu-item .dropdown-layer {
  display: none;
  position: absolute;
  top: 1.875rem;
  background-color: #FFF;
  border: 1px solid #ccc;
  box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1);
  width: 100px;
  height: 100px;
}

.item-list {
  margin-bottom: 20px;
  margin-top: 20px;
  overflow: hidden;
}

.item {
  background-color: #FFF;
  float: left;
  width: 216px;
  text-align: center;
  margin-bottom: 15px;
  margin-right: 15px;
  overflow: hidden;
  border: 1px solid #ededed;
  transition: border-color 0.2s;
}

.item:nth-child(5n) {
  margin-right: 0;
}

.item .item-inner {
  overflow: hidden;
  padding: 10px;
  display: block;
  font-size: 12px;
}

.item .item-name {
  font-size: 0.875rem;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  line-height: 1.5em;
  max-height: 3em;
  overflow: hidden;
  text-align: left;
  font-weight: normal;
}

.item .item-name a {
  color: #666;
  text-decoration: none;
}

.item .item-price {
  color: #e70012;
  font-size: 1.2em;
  font-weight: 600;
  display: inline-block;
  margin-right: 15px;
  display: flex;
  justify-content: space-around;
}

.item .item-img img {
  max-width: 100%;
  height: auto;
}

.item .s-volume {
  color: #8e8e93;
}

.item .old-price {
  text-decoration: line-through;
}

.item .item-img {
  float: none;
  width: auto;
}

.item .item-inner {
  display: block;
  border: 1px solid #c8c7cc;
  overflow: hidden;
  background-color: #FFF;
}

.item .item-img img {
  max-width: 100%;
  height: auto;
}

.item .item-info {
  margin: 5px 5px;
  height: 3em;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.item .item-price {
  color: #e70012;
  font-size: 1.2em;
  font-weight: 600;
  margin: 0 5px 0;
  padding-top: 5px;
  overflow: hidden;
}

.content-main {
  background-color: #FFF;
  float: left;
  width: 800px;
  padding: 1.6em;
}

.content-main .item-info {
  overflow: hidden;
}

.content-main .item-info .info-left {
  float: left;
  width: 230px;
  height: 230px;
  margin-right: 20px;
}

.content-main .item-info .info-right .item-price {
  font-size: 1.8em;
  color: red;
}

.content-aside {
  float: right;
  width: 330px;
}

.content-aside .box {
  background-color: #FFF;
}

.content-aside .box .box-header {
  height: 40px;
  line-height: 41px;
  padding: 0 1em;
  border-bottom: 1px solid #f5f5f5;
}

.content-aside .box .box-header .header-title {
  float: left;
}

.content-aside .box .box-header .header-more {
  float: right;
}

.content-aside .box .box-body {
  padding: 1em;
}