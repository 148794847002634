.shortcut {
    background: #f5f5f5;
    border-bottom: 1px solid #ddd;
    color: #999;
    height: 1.875rem;
    line-height: 1.875rem;
    .fl{
        float: left;
    }
    .fr {
        float: right;
    }
}
.pull-left{
    float: left;
}
.pull-right{
    float: right;
}
.shortcut a {
    color: #999;
    text-decoration: none;
}

.shortcut a:hover {
    color: #e33333;
}
.shortcut .site-menu {
    list-style: none;
    margin: 0;
    padding: 0;
}
.shortcut .site-menu li {
    float: left;
}

.shortcut li.spacer {
    overflow: hidden;
    margin: 11px 5px 0;
    width: 1px;
    height: 10px;
    background-color: #ccc;
}

.shortcut .site-menu-item {
    position: relative;
}

.shortcut .site-menu-item .dt {
    padding: 0 6px;
    z-index: 1;
}

.shortcut .site-menu-item.dropdown:hover {
    background-color: #FFF;
}

.shortcut .site-menu-item.dropdown:hover .dropdown-layer {
    display: block;
}

.shortcut .site-menu-item .dropdown-layer {
    display: none;
    position: absolute;
    top: 1.875rem;
    background-color: #FFF;
    border: 1px solid #ccc;
    box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1);
    width: 100px;
    height: 100px;
}

.item-list {
    margin-bottom: 20px;
    margin-top: 20px;
    overflow: hidden;
}

.item {
    background-color: #FFF;
    float: left;
    width: 216px;
    text-align: center;
    margin-bottom: 15px;
    margin-right: 15px;
    overflow: hidden;
    border: 1px solid #ededed;
    transition: border-color .2s;
}

.item:nth-child(5n) {
    margin-right: 0;
}

.item .item-inner {
    overflow: hidden;
    padding: 10px;
    display: block;
    font-size: 12px;
}

.item .item-name {
    font-size: 0.875rem;
    margin-top: .5em;
    margin-bottom: .5em;
    line-height: 1.5em;
    max-height: 3em;
    overflow: hidden;
    text-align: left;
    font-weight: normal;
}

.item .item-name a {
    color: #666;
    text-decoration: none;
}

.item .item-price {
    color: #e70012;
    font-size: 1.2em;
    font-weight: 600;
    display: inline-block;
    margin-right: 15px;
    display: flex;
    justify-content: space-around;
}

.item .item-img img {
    max-width: 100%;
    height: auto;
}

.item .s-volume {
    color: #8e8e93;
}

.item .old-price {
    text-decoration: line-through;
}

.item .item-img {
    float: none;
    width: auto;
}

.item .item-inner {
    display: block;
    border: 1px solid #c8c7cc;
    overflow: hidden;
    background-color: #FFF;
}

.item .item-img img {
    max-width: 100%;
    height: auto;
}

.item .item-info {
    margin: 5px 5px;
    height: 3em;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.item .item-price {
    color: #e70012;
    font-size: 1.2em;
    font-weight: 600;
    margin: 0 5px 0;
    padding-top: 5px;
    overflow: hidden;
}


.content-main {
    background-color: #FFF;
    float: left;
    width: 800px;
    padding: 1.6em;
}

.content-main .item-info {
    overflow: hidden;
}

.content-main .item-info .info-left {
    float: left;
    width: 230px;
    height: 230px;
    margin-right: 20px;
}

.content-main .item-info .info-right .item-price {
    font-size: 1.8em;
    color: red;
}

.content-aside {
    float: right;
    width: 330px;
}

.content-aside .box {
    background-color: #FFF;
}

.content-aside .box .box-header {
    height: 40px;
    line-height: 41px;
    padding: 0 1em;
    border-bottom: 1px solid #f5f5f5;
}

.content-aside .box .box-header .header-title {
    float: left;
}

.content-aside .box .box-header .header-more {
    float: right;
}

.content-aside .box .box-body {
    padding: 1em;
}
