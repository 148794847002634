
.navbar-nav{
    .nav-link{
        color: #0071bc;
        border-bottom: solid 2px transparent;
        font-weight: bold;
        &:hover, &.active {
            border-bottom-color: #edbd6a;
        }
    }
}

.licences{
    .licence{
        width: 132px;
        height: 50px;
        background-color: #FFF;
        a{
            padding: 5px;
        }
        .licence-icon{
            flex-shrink: 0;
            margin: 4px;
            width: 32px;
            height: 32px;
            background-repeat: no-repeat;
            background-image: url("../img/iconimg.png");
            &.icon-gsxt{
                background-image: url("../img/gsxt.gov.cn.png");
                background-size: contain;
            }
            &.icon-dxzhgl{
                background-image: url("../img/beian.miit.gov.cn.png");
                background-size: contain;
            }
            &.icon-12377{
                background-image: url("../img/12377.cn.png");
                background-size: contain;
            }
            &.icon-beian{
                background-position: -45px -182px;
            }
            &.icon-cyberpolice{
                background-position: -80px -180px;
            }
        }
        .licence-text{
            font-size: smaller;
            width: 5em;
            margin-left: 5px;
        }
    }
}
.block-title{
    color: #fbae17;
    position: relative;
    &:after{
        content: ' ';
        width: 2em;
        height: 1px;
        border: 2px solid #1ca2f9;
        position: absolute;
        bottom: -8px;
        left: 50%;
        margin: 0 auto 0 -1em;
    }
}
.block-subtitle{
    color: #29abe2;
    margin-bottom: 150px;
}
