html{
    //font-size: 14px;
}
body{
    color: #1d1d1f;
    line-height: 1.6;
    font-family: -apple-system-font,Helvetica Neue,sans-serif;
}
main{

}
.aside {
    flex: 0 0 auto;
    width: 280px;
}
.iconfont{
    font-size: 1em;
    line-height: 1;
}
.navbar-nav .badge{
    top: -1.5em;
    right: 5px;
    text-align: center;
    font-size: 9px;
    padding: 2px 3px;
    line-height: .9;
}
.date-range{
    .input-group-text{
        background-color: #FFF;
        border-left: none;
        border-right: none;
    }
    .form-control:focus{
        box-shadow:none;
        border-color: #ced4da;
    }
    .form-control:first-child{
        border-right: none;
    }
    .form-control:last-child{
        border-left: none;
    }
}

.label {
    display: inline;
    padding: 0.2em 0.6em 0.3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
}
a.label:hover,
a.label:focus {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}
.label:empty {
    display: none;
}
.btn .label {
    position: relative;
    top: -1px;
}
.label-default {
    background-color: #777777;
}
.label-default[href]:hover,
.label-default[href]:focus {
    background-color: #5e5e5e;
}
.label-primary {
    background-color: #337ab7;
}
.label-primary[href]:hover,
.label-primary[href]:focus {
    background-color: #286090;
}
.label-success {
    background-color: #5cb85c;
}
.label-success[href]:hover,
.label-success[href]:focus {
    background-color: #449d44;
}
.label-info {
    background-color: #5bc0de;
}
.label-info[href]:hover,
.label-info[href]:focus {
    background-color: #31b0d5;
}
.label-warning {
    background-color: #f0ad4e;
}
.label-warning[href]:hover,
.label-warning[href]:focus {
    background-color: #ec971f;
}
.label-danger {
    background-color: #d9534f;
}
.label-danger[href]:hover,
.label-danger[href]:focus {
    background-color: #c9302c;
}

html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%
}

a img {
    border: 0
}

a {
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    color: #1ca2f9;
}

.notice-box{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 65535;
    background-color: rgba(0,0,0,0.4);
    .notice-body{
        position: relative;
        background-color: #FFF;
        pointer-events: none;
        background-clip: padding-box;
        border: 1px solid rgba(0,0,0,.1);
        box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
        border-radius: .25rem;
        min-width: 300px;
    }
    .notice-icon{
        font-size: 48px;
    }
    .notice-text{

    }
}
#alert-modal{
    z-index: 65534;
}
#confirm-modal{
    z-index: 65533;
}
.btn-file{
    position: relative;
}
.btn input[type=file] {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
}
.img-upload-viewer{
    width: 218px;
    height: 137.5px;
    background-color: #FFF;
    border-radius: 8px;
    overflow: hidden;
    img.viewer{
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto
    }
    .viewer-wrap{
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #fff
    }
}

.footer-info{
    background-color: #0071BC;
    color: white;
    font-size: .8em;
}
#user-info-load{
    .btn-passport{
        border-radius: 38px;
        color: white;
    }
    .btn-login{
        background-image: linear-gradient(to right, #edbd6a, #f6a76a);
    }
    .btn-signup{
        background-image: linear-gradient(to right , #106dde, #1897f3);
    }
}
.btn-info{
    color: #FFF;
}
