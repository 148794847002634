.feature-box{
    border-radius:.6em;
    background-image: linear-gradient(to right, #45D4DB, #4DB6F5);
    min-width: 256px;
}
.feature-text{
    color: #fff;
}
.feature-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    margin-bottom: 1rem;
    font-size: 2rem;
    background-color: #FFF;
    border-radius: 50%;
}
